import React from 'react'

import ContentWrapper from '../../components/ContentWrapper'

import content from './_content.yaml'
import meta from './_meta.yaml'

export default () => (
    <ContentWrapper
      content={content}
      meta={meta}
    />
)
